// extracted by mini-css-extract-plugin
export var Recap = "checkout-module--Recap--HVgaA";
export var align = "checkout-module--align--BbcRa";
export var alternative_price = "checkout-module--alternative_price--LqgZz";
export var commande = "checkout-module--commande--KAet+";
export var consent_title = "checkout-module--consent_title--nZwQ+";
export var container = "checkout-module--container--rs-0p";
export var contents_icon = "checkout-module--contents_icon--KsWeQ";
export var contents_items = "checkout-module--contents_items--jghNy";
export var contents_single_item = "checkout-module--contents_single_item--UskNM";
export var euro = "checkout-module--euro--ooq12";
export var forms = "checkout-module--forms--Qy7A1";
export var forms__wrapper = "checkout-module--forms__wrapper--4WLzZ";
export var header = "checkout-module--header--mkDFw";
export var know_more = "checkout-module--know_more--jn1l1";
export var know_more_img = "checkout-module--know_more_img--Dkxd-";
export var know_more_text = "checkout-module--know_more_text--bVmGX";
export var logo = "checkout-module--logo--jrxep";
export var main = "checkout-module--main--HeihR";
export var payment = "checkout-module--payment--ceice";
export var productItem = "checkout-module--productItem--ArTUc";
export var products = "checkout-module--products--KvisW";
export var quantity = "checkout-module--quantity--nII2h";
export var recapCommande = "checkout-module--recapCommande--rSA0k";
export var recapTitle = "checkout-module--recapTitle--D2CB1";
export var regularWeight = "checkout-module--regularWeight--qY+Dw";
export var relay = "checkout-module--relay--cIAEX";
export var same_billing = "checkout-module--same_billing--FteZL";
export var select = "checkout-module--select--4L3kw";
export var shipping_promo = "checkout-module--shipping_promo--LjFe-";
export var small = "checkout-module--small--pAUH5";
export var spacer = "checkout-module--spacer--KD6TH";
export var strikethrough = "checkout-module--strikethrough--ZgMvj";
export var t2 = "checkout-module--t2--axGs7";
export var total = "checkout-module--total--x+0eC";
export var totalLine = "checkout-module--totalLine--8hS0M";
export var total_wrapper = "checkout-module--total_wrapper--qsjRK";
export var wrapper = "checkout-module--wrapper--Ux0Vo";